import React from "react"
import Layout from "../components/layout";
import {graphql} from "gatsby";
import * as dayjs from "dayjs";
import _ from "lodash";
import AlbumSlider from "../components/album-slider";
import Seo from "../components/seo";

const ReleasePage = ({data: { albums } }) => {
    return (
        <Layout>
            <Seo title={"Releases"} description={""} />
            <section className={"w-full bg-white pt-10 flex flex-col items-center pb-10 font-primary text-black"}>
                <div className={"jdb--menu-spacer"} />
                <div className={"pb-2"}><h1 className={"h1"}>Releases</h1></div>
                <div className={"flex flex-col w-5/6 lg:w-2/3 mt-10 divide-y divide-dashed divide-gray-700 space-y-3"}>
                    <AlbumSlider albums={albums.nodes} />
                </div>
            </section>
        </Layout>
    )
};

export default ReleasePage;


export const pageQuery = graphql`
query ReleasesQuery {
     albums: allSpotifyArtistAlbum(sort: {fields: release_date, order: DESC}) {
        nodes {
          name
          images {
            width
            height
            url
          }
          release_date
          uri
          external_urls {
            spotify
          }
          album_type
          album_group
          total_tracks
        }
      }
      
}
`
